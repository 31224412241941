<template>
  <span>
    {{ prepend }}{{ timeCountSecondsDisplay }}{{ append }}
  </span>
</template>

<script>
export default {
  props: {
    // data
    timeSeconds: {
      type: Number,
      default: 0,
    },
    prepend: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: '',
    },

    // param
    // 倒数
    backwards: {
      type: Boolean,
      default: false,
    },

    // action
    startToggerTrigger: {
      type: Number,
      default: 0,
    },

    // display
    // extendable - display
    // hmr: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data () {
    return {
      // data
      timeCountSeconds: 0,
      timeEndSeconds: 0,
    }
  },
  computed: {
    timeCountSecondsDisplay () {
      return this.backwards ? this.timeEndSeconds - this.timeCountSeconds : this.timeCountSeconds
    },
  },
  methods: {
    // action
    countStart () {
      this.resetTimeSeconds()
      this.count()
    },
    count () {
      if (this.timeEndSeconds > this.timeCountSeconds) {
        setTimeout(() => {
            this.timeCountSeconds += 1
            this.count()
        }, 1000)
      } else {
        this.countDone()
      }
    },
    countDone () {
      this.$emit('done')
    },

    // function
    resetTimeSeconds () {
      this.timeCountSeconds = 0
      this.timeEndSeconds = (typeof this.timeSeconds === 'number' && this.timeSeconds > 0) ? this.timeSeconds : 0
    },
  },
  watch: {
    startToggerTrigger: {
      handler (nv, ov) {
        if (nv === ov) { return }
        this.countStart()
      }
    },
  }
}
</script>

<style>

</style>